import { archiveFilters } from "../../templates/OverviewTable/actions";

export default {
    columns: [
        {
            key: "companyId",
            label: "Company",
            width: "5%",
        },
        {
            key: "sceneName",
            label: "Scene name",
            width: "23%",
            multiline: true,
        },
        {
            key: "assetType",
            label: "Asset type",
            width: "9%",
        },
        {
            key: "roomType",
            label: "Room type",
            width: "9%",
        },
        {
            key: "floorType",
            label: "Floor type",
            width: "9%",
        },
        {
            key: "wallColour",
            label: "Wall colour",
            width: "9%",
        },
        {
            key: "lightSetting",
            label: "Lightning",
            width: "9%",
        },
        {
            key: "modificationTime",
            label: "Last modified",
            defaultSortOrder: "desc",
            width: "8%",
        },
        {
            key: "version",
            label: "Version",
            width: "5%",
        },
        {
            key: "published",
            label: "Status",
            multiline: true,
            width: "10%",
        },
    ],
    itemsPerPage: 50,
    pageSizeOptions: [
        { label: "10", value: "10" },
        { label: "25", value: "25" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
    ],
    customFilters: archiveFilters,
    searchLabel: "Search scene name, company etc.",
    sortOrder: "desc",
    rowId: "versionId",
};
