//@flow
import { LoadIndicator } from "@brutextiles/web-component-library";
import React, { type Node } from "react";

import { SetType } from "../../../../hooks/use-material-sets";
import { VersionCell } from "../../../index";
import type { Actions, Settings } from "../../index";
import type { MaterialVersion } from "../../types/material-version.d";
import MateriaSetTableBodyRow from "../MaterialSetTableBodyRow";
import style from "./style.module.scss";

type Props = {
    data: {
        [string]: any,
    }[],
    setType?: string,
    setIndex: number,
    settings: Settings,
    loading?: boolean,
    removedIds?: Set<string>,
    addedIds?: Set<string>,
    changedVersionIds?: { [string]: string },
    changedRotations?: { [string]: number },
    editMode: boolean,
    actions: Actions[],
    onAction: (action: string, row: MaterialVersion) => void,
    onChangeRotation: (materialVersionId: string, rotation: number) => void,
    addedMaterials: {
        [string]: any,
    }[],
};

const calcRotation = (
    materialVersionId: string,
    changedRotations?: { [string]: number },
    persistedRotation: number,
) => {
    if (
        !changedRotations ||
        changedRotations[materialVersionId] === undefined
    ) {
        return persistedRotation || 0;
    }

    return changedRotations[materialVersionId];
};

const MaterialSetTableBody = ({
    data,
    settings,
    editMode,
    setIndex,
    removedIds,
    addedIds,
    changedVersionIds,
    changedRotations,
    actions,
    setType = SetType.SELECTION,
    onAction: handleAction,
    onChangeRotation: handleChangeRotation,
    loading = false,
    addedMaterials,
}: Props): Node => (
    <tbody>
        {addedIds &&
            setType === SetType.SELECTION &&
            addedMaterials
                .filter(row => addedIds.has(row.materialVersionId))
                .map((row, index) => (
                    <MateriaSetTableBodyRow
                        key={row.materialVersionId}
                        index={index}
                        setIndex={setIndex}
                        setType={setType}
                        editMode={editMode}
                        row={{
                            ...row,
                            companyId: row.materialId.companyId,
                            skuId: row.materialId.skuId,
                            version: (
                                <VersionCell
                                    cellId={`tt_${setIndex}_${index}_status`}
                                    version={row.materialVersion}
                                    versionIndicator={row.versionIndicator}
                                />
                            ),
                        }}
                        rotation={calcRotation(
                            row.materialVersionId,
                            changedRotations,
                            row.rotation,
                        )}
                        settings={settings}
                        actions={actions}
                        onAction={handleAction}
                        onChangeRotation={rotation =>
                            handleChangeRotation(
                                row.materialVersionId,
                                rotation,
                            )
                        }
                        added
                    />
                ))}
        {loading && (
            <tr>
                {/* + 1 to include the hardcoded first column */}
                <td
                    colSpan={settings.columns.length + 1}
                    className={style.loadingCell}
                >
                    <LoadIndicator
                        rows={data?.length || settings.itemsPerPage || 15}
                        cols={1}
                    />
                </td>
            </tr>
        )}
        {!loading &&
            data?.map((row, index) => (
                <MateriaSetTableBodyRow
                    key={row.materialVersionId}
                    index={index}
                    setIndex={setIndex}
                    setType={setType}
                    editMode={editMode}
                    removed={
                        removedIds && removedIds.has(row.materialVersionId)
                    }
                    versionChanged={
                        changedVersionIds &&
                        changedVersionIds[row.materialVersionId] !== undefined
                    }
                    row={mapRow(
                        setIndex,
                        index,
                        changedVersionIds,
                        addedMaterials,
                        row,
                    )}
                    rotation={calcRotation(
                        row.materialVersionId,
                        changedRotations,
                        row.rotation,
                    )}
                    settings={settings}
                    actions={actions}
                    onChangeRotation={rotation =>
                        handleChangeRotation(row.materialVersionId, rotation)
                    }
                    onAction={handleAction}
                />
            ))}
    </tbody>
);

const mapRow = (setIndex, rowIndex, changedVersionIds, addedMaterials, row) => {
    let result = {
        ...row,
        version: (
            <VersionCell
                cellId={`tt_${setIndex}_${rowIndex}_status`}
                version={row.version}
                versionIndicator={row.versionIndicator}
            />
        ),
    };

    if (changedVersionIds && changedVersionIds[row.materialVersionId]) {
        const newMaterial = addedMaterials.find(
            entry =>
                entry.materialVersionId ===
                changedVersionIds[row.materialVersionId],
        );

        if (newMaterial) {
            result = {
                ...row,
                ...newMaterial,
                version: (
                    <VersionCell
                        cellId={`tt_${setIndex}_${rowIndex}_status`}
                        version={newMaterial.materialVersion}
                        versionIndicator={newMaterial.versionIndicator}
                    />
                ),
            };
        }
    }

    return result;
};

export default MaterialSetTableBody;
