//@flow

import {
    Datatable,
    Icon,
    InlineEdit,
    Modal,
    Plus,
} from "@brutextiles/web-component-library";
import classnames from "classnames";
import React, { type Node, useEffect, useState } from "react";
import { Button, Col, Collapse, Input } from "reactstrap";

import { useValidation } from "../../hooks";
import style from "./style.module.scss";
import Header from "./subComponents/Header";
import ViewSetTableBody from "./subComponents/ViewSetTableBody";

export type Settings = {
    columns: {
        key: string,
        label: string,
        editable?: boolean,
        thumbnail?: boolean,
        formatter?: (value: any) => string,
    }[],
    rowId: string,
};

export type Actions = {
    icon: Node,
    label: string,
    action: string,
    enable?: {
        key: string,
        condition: () => boolean,
    },
}[];

type Props = {
    settings: Settings,
    actions: Actions,
    data: {
        [string]: any,
    }[],
    title: string,
    order: number,
    onDelete: () => void,
    open: boolean,
    enableEdit?: boolean,
    onCancel: () => void,
    onAddItem: () => void,
    onUpdateSetValue: (number, string, string) => void,
    onUpdateTitle: string => void,
    onAction: (action: string, index: number) => void,
    loading: boolean,
    onSave: () => Promise<boolean>,
    nameField?: string,
    onToggle: () => void,
    deleteConfirmationLabel: string,
    readOnly?: boolean,
    resultsMapper?: (
        {
            [string]: any,
        }[],
    ) => {
        [string]: any,
    }[],
};

const ViewSet = ({
    settings,
    data,
    order,
    title,
    onDelete: handleDelete,
    open,
    enableEdit,
    onCancel: handleCancel,
    onSave: handleSave,
    onAddItem: handleAddItem,
    actions,
    onUpdateSetValue: handleUpdateSetValue,
    onUpdateTitle: handleUpdateTitle,
    onAction: handleAction,
    loading,
    nameField,
    onToggle: handleToggle,
    deleteConfirmationLabel,
    resultsMapper,
    readOnly,
}: Props): Node => {
    const [editMode, setEditmode] = useState(!!enableEdit);
    const [showModal, setShowModal] = useState();
    useEffect(() => setEditmode(!!enableEdit), [enableEdit]);

    const cancel = () => {
        unregisterFields();
        setEditmode(false);
        handleCancel();
    };

    const handleActionSelect = (action, index) => {
        if (action === "delete") {
            unregisterFields();
        }
        setEditmode(true);
        handleAction(action, index);
    };

    const [{ formik }, submit, unregisterFields] = useValidation({
        title,
        data,
        nameField,
        onSave: async () => {
            const success = await handleSave();
            if (success) {
                setEditmode(false);
            }
        },
    });

    const confirmDelete = (): void => {
        setShowModal(false);
        handleDelete();
    };

    return (
        <div
            className={classnames(
                "px-0",
                style.viewSetBody,
                editMode ? style.expanded : style.collapsed,
            )}
        >
            <InlineEdit
                isSubmitting={loading}
                active={editMode}
                onCancel={cancel}
                onSave={submit}
                isOpen={open}
                saveAllowed={formik.isValid}
            >
                <Header
                    order={order}
                    onToggle={handleToggle}
                    editMode={editMode}
                    isOpen={open}
                    title={title}
                    onDelete={() => setShowModal(true)}
                    onEnableEditMode={() => setEditmode(true)}
                    onUpdateTitle={handleUpdateTitle}
                    loading={loading}
                    validationError={formik.errors["title"]}
                    readOnly={readOnly}
                >
                    <Col>
                        {editMode ? (
                            <Input
                                onChange={event =>
                                    handleUpdateTitle(event.target.value)
                                }
                                value={title}
                            />
                        ) : (
                            title
                        )}
                        {formik.errors["title"] && (
                            <small className="text-danger">
                                {formik.errors["title"]}
                            </small>
                        )}
                    </Col>
                </Header>
                <Collapse isOpen={open}>
                    <Datatable
                        settings={settings}
                        disableRowSelect
                        hideNavigation
                        disableSort
                    >
                        <ViewSetTableBody
                            setIndex={order}
                            settings={settings}
                            data={resultsMapper ? resultsMapper(data) : data}
                            editMode={editMode}
                            onUpdateValue={handleUpdateSetValue}
                            actions={readOnly ? [] : actions}
                            onActionSelect={handleActionSelect}
                            errors={formik.errors}
                        />
                    </Datatable>
                    {editMode && (
                        <Button
                            className={`w-100 text-secondary py-2`}
                            color="light-grey"
                            onClick={handleAddItem}
                        >
                            <Icon
                                icon={Plus}
                                className="mr-2"
                                variant="secondary"
                            />
                            Add items
                        </Button>
                    )}
                </Collapse>
            </InlineEdit>
            <Modal
                confirmation
                open={showModal}
                title={"confirmation"}
                onCancel={() => setShowModal(false)}
                actions={[
                    {
                        type: "primary",
                        label: "Delete",
                        action: confirmDelete,
                    },
                    {
                        type: "secondary",
                        label: "Cancel",
                        action: () => setShowModal(false),
                    },
                ]}
            >
                {deleteConfirmationLabel}
            </Modal>
        </div>
    );
};

export default ViewSet;
