export default {
    columns: [
        {
            key: "companyId",
            label: "Company ID",
        },
        {
            key: "companyName",
            label: "Company name",
        },
        {
            key: "description",
            label: "Description",
        },
        {
            key: "modificationTime",
            label: "Last modified",
            defaultSortOrder: "desc",
        },
    ],
    rowId: "companyId",
    searchLabel: "Search Company ID, Company name, etc.",
    itemsPerPage: 50,
    pageSizeOptions: [
        { label: "10", value: "10" },
        { label: "25", value: "25" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
    ],
};
