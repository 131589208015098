import { pageSizeOptions } from "../../RendersOverview/settings/render-table";

export default {
    columns: [
        { key: "components.launch", label: "Launch" },
        { key: "components.colour", label: "Colour" },
        { key: "components.endUse", label: "End Use" },
        { key: "resolution", label: "Resolution" },
        { key: "components.skuId", label: "Sku" },
        { key: "components.materialType", label: "Material Type" },
        { key: "status", label: "Status" },
        { key: "components.materialSetName", label: "Material Set" },
        { key: "components.materialId", label: "Material Id" },
        { key: "components.design", label: "Design" },
        { key: "components.materialCompanyId", label: "Material Company" },
        { key: "components.brand", label: "Brand" },
        { key: "viewSetName", label: "View Set" },
        { key: "components.collection", label: "Collection" },
        { key: "viewName", label: "View" },
    ],
    rowId: "imageRequestDefinitionId",
    searchLabel: "Search SKU ID, company, etc.",
    customSearchLabels: {
        pairingLabel: "Pairing label",
    },
    itemsPerPage: 12,
    pageSizeOptions: pageSizeOptions,
};
