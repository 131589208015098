// @flow

import {
    Button,
    Datatable,
    Heading,
    Modal,
    Spinner,
} from "@brutextiles/web-component-library";
import { navigate } from "gatsby";
import React, { type Node, useState } from "react";
import { Container } from "reactstrap";

import { useSuggestions, useTableActions, useTableData } from "../../hooks";
import style from "./overview-table.module.scss";
import { type Action } from "./types/action.d";
import { type Column } from "./types/column.d";
import { type TableData, type TableElement } from "./types/table-data.d";

type Props = {
    title: string,
    multiSelect?: boolean,
    apiBase: string,
    searchSuffix?: string,
    actionApiBase?: string,
    canManage?: boolean,
    createPageLink?: string,
    createButtonLabel: string,
    customCreateAction?: () => void,
    customFilter?: { [string]: string },
    disableRowSelect?: boolean,
    tableSettings: {
        columns: Column[],
        actionLabel?: string,
        sortField?: string,
        itemsPerPage?: number,
        searchLabel: string,
        customSearchLabels?: { [string]: string },
        customFilters?: {
            label: string,
            value: { value: string, field: string },
        }[],
    },
    actions?: Action[],
    mapResults: TableData => TableData,
    tableActionsHandler?: any => void,
    identifiers: string[],
    updateLinkBase: string,
    loading?: boolean,
    refresh?: boolean,
    refreshRate?: number,
    onExpandRow?: (row: TableElement) => Node,
    onCustomDataTableAction?: (
        action: string,
        data: { [string]: any } | { [string]: any }[],
        fetchResults: () => void,
        executeDefaultTableAction: (
            action: string,
            data: { [string]: any } | { [string]: any }[],
        ) => void,
    ) => void,
};

const OverviewTable = ({
    title,
    apiBase,
    searchSuffix,
    actionApiBase,
    updateLinkBase,
    createPageLink,
    createButtonLabel,
    customCreateAction,
    tableSettings,
    canManage = false,
    customFilter,
    actions = [],
    mapResults,
    identifiers,
    loading = false,
    refresh = false,
    refreshRate = 60,
    multiSelect = false,
    disableRowSelect = false,
    onExpandRow,
    onCustomDataTableAction,
}: Props): Node => {
    const [showModal, setShowModal] = useState();

    const searchSettings = () => {
        const result = {
            size: tableSettings.itemsPerPage || 50,
            sortField: tableSettings.sortField,
            customFilter: {},
        };

        if (customFilter) {
            result.customFilter = customFilter;
        } else if (tableSettings?.customFilters?.length) {
            result.customFilter = {
                [tableSettings?.customFilters[0].value.field]:
                    tableSettings?.customFilters[0].value.value,
            };
        }

        return result;
    };
    const [
        { tableData, tableDataLoading, page },
        changeSorting,
        changeFilters,
        search,
        setCustomFilter,
        ,
        changePageInfo,
    ] = useTableData(
        searchSettings(),
        apiBase,
        refresh && refreshRate,
        false,
        searchSuffix,
    );

    const [
        { suggestions, suggestionsLoading },
        setSuggestionFilters,
        setFilterQuery,
    ] = useSuggestions(apiBase, customFilter);

    const [
        { actionLoading, actionParameters },
        setActionParameters,
        confirmAction,
    ] = useTableActions({
        fetchResults: search,
    });

    const createButton = canManage => {
        if (canManage) {
            return (
                <Button
                    disabled={!canManage}
                    onMouseDown={e => e?.preventDefault()}
                    onKeyUp={e =>
                        (e.keyCode === 13 || e.keyCode === 32) &&
                        e?.preventDefault()
                    }
                    onClick={() =>
                        customCreateAction
                            ? customCreateAction()
                            : navigate(createPageLink)
                    }
                >
                    {loading ? <Spinner size="sm" /> : createButtonLabel}
                </Button>
            );
        }
    };

    const getIdentifiersString = (
        data: { [string]: any },
        delimiter?: string,
    ): string => {
        let identifiersString = "";
        identifiers.forEach((identifier, index) => {
            identifiersString +=
                index >= identifiers.length - 1
                    ? data[identifier]
                    : `${data[identifier]}${delimiter ? delimiter : "-"}`;
        });
        return identifiersString;
    };

    const handleDataTableAction = (
        action: string,
        data: { [string]: any } | { [string]: any }[],
    ): void => {
        let item;

        if (Array.isArray(data) && data.length > 0) {
            item = data[0];
        } else {
            item = data;
        }

        if (item && !Array.isArray(item)) {
            if (action === "history") {
                navigate(
                    `/${updateLinkBase}/history/${getIdentifiersString(item)}`,
                );
            } else if (
                [
                    "publish",
                    "unpublish",
                    "download",
                    "archive",
                    "unarchive",
                ].includes(action)
            ) {
                setActionParameters({
                    baseUrl: `${
                        actionApiBase || apiBase
                    }/${getIdentifiersString(item, "/")}`,
                    action,
                });
                if (action !== "download") {
                    setShowModal(true);
                }
            }
        }
    };

    const setFilters = (filters: Array<{ [string]: string }>): void => {
        changeFilters(filters);
        setSuggestionFilters(filters);
    };

    const setVersionFilter = (filter: {
        field: string,
        value: string,
    }): void => {
        if (filter && filter.field !== "all" && filter.value) {
            setCustomFilter(draft => ({
                ...draft,
                [filter.field]: filter.value,
            }));
        } else {
            setCustomFilter(() => (customFilter ? { ...customFilter } : {}));
        }
    };

    const confirm = (): void => {
        setShowModal(false);
        confirmAction();
    };

    return (
        <Container fluid>
            <Heading title={title} rightContent={createButton(canManage)} />
            <div className={style.overviewTableWrapper}>
                <Datatable
                    settings={tableSettings}
                    disableRowSelect={disableRowSelect}
                    data={
                        tableData?.results ? mapResults(tableData.results) : []
                    }
                    page={page}
                    searchResults={suggestions?.result}
                    totalResults={tableData?.totalResults}
                    actions={actions}
                    searchIsLoading={suggestionsLoading || actionLoading}
                    dataIsLoading={tableDataLoading}
                    actionLoading={actionLoading}
                    onRefresh={search}
                    onSortChange={changeSorting}
                    onPageInfoChange={changePageInfo}
                    onSearchChange={setFilterQuery}
                    onFilterChange={setFilters}
                    onMultiSortChange={changeSorting}
                    onCustomFilterChange={setVersionFilter}
                    onClickRow={item => {
                        navigate(
                            `/${updateLinkBase}/update/${getIdentifiersString(
                                item,
                            )}`,
                        );
                    }}
                    onExpandRow={onExpandRow}
                    onAction={(action, selectedRows) => {
                        if (onCustomDataTableAction) {
                            onCustomDataTableAction(
                                action,
                                selectedRows,
                                search,
                                handleDataTableAction,
                            );
                        } else {
                            handleDataTableAction(action, selectedRows);
                        }
                    }}
                    multiSelect={multiSelect}
                    cacheSearchFilters
                    cachePaginationInfo
                    cacheCustomFilter
                    cacheSorting
                    multiSort
                />
            </div>
            <Modal
                confirmation
                open={showModal}
                title={"confirmation"}
                onCancel={() => setShowModal(false)}
                actions={[
                    {
                        type: "primary",
                        label: actionParameters.action,
                        action: confirm,
                    },
                    {
                        type: "secondary",
                        label: "Cancel",
                        action: () => setShowModal(false),
                    },
                ]}
            >
                Are you sure you want to {actionParameters.action}{" "}
                <strong className="text-secondary">1</strong> item?
            </Modal>
        </Container>
    );
};

export default OverviewTable;
