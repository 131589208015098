// @flow
import {
    Datatable,
    Heading,
    Tabs,
    useNotifications,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import React, { type Node, Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";

import { Permissions } from "../../api/permissions";
import { ErrorHandling, RenderDetails, RenderTable } from "../../components";
import defaultColumns from "../../components/RenderDetails/defaultColumns";
import { usePermissions, useSuggestions, useTableData } from "../../hooks";
import { dateTimeFormatter } from "../../utils/formatter";
import projectTableSettings from "../ProjectsOverview/settings/table";
import renderDetailsSettings from "./settings/render-details";
import tableSettings from "./settings/render-table";
import tableActions from "./settings/table-actions";
import tabs from "./settings/tabs";
import style from "./style.module.scss";

const RendersOverview = (): Node => {
    const { addNotification } = useNotifications();

    const [canRead, canDownload, canSeeErrorCause] = usePermissions([
        Permissions.RENDER_READ,
        Permissions.RENDER_DOWNLOAD,
        Permissions.ERROR_CAUSE,
    ]);

    const [{ data: fileData, loading: fileInfoLoading }, getFileUrl] = useAxios(
        {},
        {
            manual: true,
            useCache: false,
        },
    );

    useEffect(() => {
        if (fileData?.url) {
            window.open(fileData?.url);
        }
    }, [fileData]);

    const [{ loading: downloadBatchLoading }, downloadBatch] = useAxios(
        {
            url: `/ams-api/rendition/archive/content`,
            method: "POST",
            params: {
                subject: "test",
            },
        },
        {
            manual: true,
            useCache: false,
        },
    );

    const handleAction = async (action: string, selected: string[]) => {
        if (action === "download") {
            try {
                await downloadBatch({
                    data: selected,
                });
                addNotification({
                    type: "success",
                    body: "You will soon receive a mail with a download link for the selected renders.",
                    autoHide: true,
                    timeout: 5000,
                });
            } catch {
                addNotification({
                    type: "danger",
                    body: "Something went wrong. Please try again later",
                    autoHide: true,
                    timeout: 5000,
                });
            }
        }
    };

    return (
        <Container fluid>
            <Helmet>
                <title>Twinbru Engine - Renders</title>
            </Helmet>
            <Heading title="Render overview" />
            <ErrorHandling />
            {canRead && (
                <RenderTable
                    settings={tableSettings}
                    actions={() => tableActions}
                    onAction={handleAction}
                    apiBase={`dap-search-service/rendition`}
                    idField="renditionId"
                    actionLoading={downloadBatchLoading}
                    fileInfoLoading={fileInfoLoading}
                    onSelectDownload={(type, render) => {
                        if (type === ".jpeg") {
                            getFileUrl({
                                url: `/ams-api/rendition/${render?.renditionId}/content`,
                            });
                        } else {
                            getFileUrl({
                                url: `/ams-api/rendition/${render?.renditionId}/intermediate-renders?intermediateRenderType=${type}`,
                            });
                        }
                    }}
                    renderDetails={render => (
                        <RenderOverviewRenderDetails
                            render={render}
                            canSeeErrorCause={canSeeErrorCause}
                            canDownload={canDownload}
                        />
                    )}
                />
            )}
        </Container>
    );
};

type RendersOverviewRenderDetailsProps = {
    render: { [key: string]: string },
    canDownload: boolean,
    canSeeErrorCause: boolean,
};

const RenderOverviewRenderDetails = ({
    render,
    canDownload,
    canSeeErrorCause,
}: RendersOverviewRenderDetailsProps): Node => {
    const [activeTab, setActiveTab] = useState(0);

    const [
        { data: renderDetailsData, loading: renderDetailsLoading },
        loadRenderDetails,
    ] = useAxios({}, { manual: true, useCache: false });

    const [
        {
            tableData: projectsData,
            tableDataLoading: projectsDataLoading,
            page,
        },
        changeSorting,
        changeFilters,
        search,
        ,
        searchSelected,
        changePageInfo,
    ] = useTableData(
        {
            size: tableSettings.itemsPerPage || 10,
            customFilter: { latestVersion: true },
        },
        "dap-search-service/project",
        60,
    );

    const [
        { suggestions, suggestionsLoading },
        setSuggestionFilters,
        setFilterQuery,
    ] = useSuggestions("dap-search-service/project");

    const setFilters = (filters: Array<{ [string]: string }>): void => {
        changeFilters(filters);
        setSuggestionFilters(filters);
    };

    useEffect(() => {
        if (activeTab === 1 && renderDetailsData) {
            searchSelected(renderDetailsData.projectIds);
        }
    }, [activeTab, renderDetailsData]);

    useEffect(() => {
        if (
            render?.renditionId &&
            render?.renditionId !== renderDetailsData?.renditionId
        ) {
            loadRenderDetails({
                url: `/ams-api/rendition/${render.renditionId}/extended-metadata`,
            });
        }
    }, [render]);
    return (
        <Fragment>
            <div className={style.tabsHeader}>
                <Tabs
                    tabs={tabs}
                    onClick={e => {
                        if (e) {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }}
                    onTabChange={tab => setActiveTab(tab.id)}
                    activeTab={activeTab}
                />
            </div>
            {activeTab === 0 && (
                <RenderDetails
                    canSeeErrorCause={canSeeErrorCause}
                    canDownload={canDownload}
                    formData={{
                        ...renderDetailsData,
                        renditionTime: dateTimeFormatter(
                            renderDetailsData?.renditionTime,
                        ),
                    }}
                    tableData={renderDetailsData?.components || []}
                    tableSettings={{
                        columns: [
                            ...defaultColumns,
                            {
                                key: "version",
                                label: "Version",
                            },
                        ],
                    }}
                    formSettings={renderDetailsSettings}
                    thumbnailUrl={renderDetailsData?.thumbnailUrl}
                    loading={renderDetailsLoading}
                />
            )}
            {activeTab === 1 && (
                <div className={style.projectsDetailsTableWrapper}>
                    <Datatable
                        settings={{
                            ...projectTableSettings(false),
                            itemsPerPage: 50,
                            pageSizeOptions: [
                                { label: "10", value: "10" },
                                { label: "25", value: "25" },
                                { label: "50", value: "50" },
                                { label: "100", value: "100" },
                            ],
                        }}
                        data={
                            renderDetailsData?.projectIds?.length
                                ? projectsData?.results
                                      .filter(row =>
                                          renderDetailsData?.projectIds.includes(
                                              row.projectId,
                                          ),
                                      )
                                      .map(project => ({
                                          ...project,
                                          modificationTime: dateTimeFormatter(
                                              project?.modificationTime,
                                          ),
                                      }))
                                : []
                        }
                        page={page}
                        onPageInfoChange={changePageInfo}
                        onMultiSortChange={changeSorting}
                        onRefresh={() => {
                            search();
                        }}
                        searchResults={suggestions?.result}
                        totalResults={renderDetailsData?.projectIds?.length}
                        dataIsLoading={projectsDataLoading}
                        searchIsLoading={suggestionsLoading}
                        onFilterChange={setFilters}
                        onSearchChange={setFilterQuery}
                        disableRowSelect
                        multiSort
                        cacheSearchFilters
                        cachePaginationInfo
                        cacheSorting
                    />
                </div>
            )}
        </Fragment>
    );
};

export default RendersOverview;
