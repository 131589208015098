//@flow
import {
    ArrowLeft,
    ArrowRight,
    Button,
    Icon,
    Information,
    Search,
    Spinner,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import React, { type Node, useEffect, useState } from "react";

import { RenderDetails, RendersGallery } from "../../components";
import defaultColumns from "../../components/RenderDetails/defaultColumns";
import renderDetailsSettings from "../../containers/RendersOverview/settings/render-details";
import { useTableData } from "../../hooks";
import { dateTimeFormatter } from "../../utils/formatter";
import style from "./style.module.scss";

const PAGE_SIZE = 5;

type Props = {
    rendersUrl: string,
    canDownload: boolean,
    isAdmin: boolean,
};

const RenderSlider = ({ rendersUrl, canDownload, isAdmin }: Props): Node => {
    const [{ tableData, tableDataLoading, page }, , , , , , changePageInfo] =
        useTableData(
            { size: PAGE_SIZE, sortField: "renditionTime", sortOrder: "asc" },
            rendersUrl,
        );

    const [{ data: fileData, loading: fileInfoLoading }, getFileUrl] = useAxios(
        {},
        {
            manual: true,
            useCache: false,
        },
    );

    useEffect(() => {
        if (fileData?.url) {
            window.open(fileData?.url);
        }
    }, [fileData]);

    const [galleryState, setGalleryState] = useState({
        isOpen: false,
        showDetails: false,
        position: 0,
    });

    const hidePrev = tableDataLoading || page === 0;
    const hideNext =
        tableDataLoading ||
        page >= Math.ceil(tableData?.totalResults / PAGE_SIZE) - 1;

    const leftButton = (
        <Button
            color={"light"}
            disabled={hidePrev}
            onClick={() => changePageInfo({ size: PAGE_SIZE, page: page - 1 })}
        >
            <ArrowLeft />
        </Button>
    );

    const rightButton = (
        <Button
            color={"light"}
            disabled={hideNext}
            onClick={() => changePageInfo({ size: PAGE_SIZE, page: page + 1 })}
        >
            <ArrowRight />
        </Button>
    );

    return (
        <div className={style.sliderContainer}>
            {tableData && (
                <div className={style.sliderContainerWrapper}>
                    {tableData?.results.length > 0 && leftButton}
                    {tableData?.results.map(
                        ({ renderId, thumbnailUrl }, index) => (
                            <ThumbnailImage
                                key={renderId}
                                renderId={renderId}
                                thumbnailUrl={thumbnailUrl}
                                onClickImage={() =>
                                    setGalleryState(() => ({
                                        isOpen: true,
                                        showDetails: false,
                                        position: index,
                                    }))
                                }
                                onClickInfo={() =>
                                    setGalleryState(() => ({
                                        isOpen: true,
                                        showDetails: true,
                                        position: index,
                                    }))
                                }
                            />
                        ),
                    )}
                    {tableData?.results.length > 0 && rightButton}
                </div>
            )}
            {!tableData && (
                <div className={style.sliderContainerLoader}>
                    <Spinner size="lg" />
                </div>
            )}
            {tableData?.results.length === 0 && (
                <div className={style.sliderContainerLoader}>
                    <div>No QC renders available</div>
                </div>
            )}
            <RendersGallery
                {...galleryState}
                showNext={!hideNext}
                showPrev={!hidePrev}
                renders={tableData?.results}
                loading={tableDataLoading}
                fileInfoLoading={fileInfoLoading}
                canDownload={canDownload}
                setShowDetails={show =>
                    setGalleryState(state => ({
                        ...state,
                        showDetails: show,
                    }))
                }
                onHide={() =>
                    setGalleryState(() => ({
                        isOpen: false,
                        showDetails: false,
                        position: 0,
                    }))
                }
                onPositionChange={position => {
                    if (position === -1) {
                        changePageInfo({ size: PAGE_SIZE, page: page - 1 });
                        setGalleryState(state => ({
                            ...state,
                            position: PAGE_SIZE - 1,
                        }));
                    } else if (position === tableData?.results.length) {
                        changePageInfo({ size: PAGE_SIZE, page: page + 1 });
                        setGalleryState(state => ({
                            ...state,
                            position: 0,
                        }));
                    } else {
                        setGalleryState(state => ({
                            ...state,
                            position: position,
                        }));
                    }
                }}
                onSelectDownload={(type, render) => {
                    if (type === ".jpeg") {
                        getFileUrl({
                            url: `/ams-api/rendition/${render?.renditionId}/content`,
                        });
                    } else {
                        getFileUrl({
                            url: `/ams-api/rendition/${render?.renditionId}/intermediate-renders?intermediateRenderType=${type}`,
                        });
                    }
                }}
                renderDetails={render => (
                    <MaterialOverviewRenderDetails
                        render={render}
                        canSeeErrorCause={isAdmin}
                    />
                )}
            />
        </div>
    );
};

type MaterialOverviewRenderDetailsProps = {
    render: { [key: string]: string },
    canSeeErrorCause: boolean,
};

const MaterialOverviewRenderDetails = ({
    render,
    canSeeErrorCause,
}: MaterialOverviewRenderDetailsProps): Node => {
    const [
        { data: selectedRenderData, loading: renderDetailsLoading },
        loadRenderDetails,
    ] = useAxios({}, { manual: true, useCache: false });

    useEffect(() => {
        if (
            render?.renditionId &&
            selectedRenderData?.renditionId !== render?.renditionId
        ) {
            loadRenderDetails({
                url: `/ams-api/rendition/${render.renditionId}/extended-metadata`,
            });
        }
    }, [render]);

    return (
        <RenderDetails
            canSeeErrorCause={canSeeErrorCause}
            formData={{
                ...selectedRenderData,
                renditionTime: dateTimeFormatter(
                    selectedRenderData?.renditionTime,
                ),
            }}
            tableData={selectedRenderData?.components || []}
            tableSettings={{
                columns: [
                    ...defaultColumns,
                    {
                        key: "version",
                        label: "Version",
                    },
                ],
            }}
            formSettings={renderDetailsSettings}
            thumbnailUrl={render?.thumbnailUrl}
            loading={renderDetailsLoading}
        />
    );
};

type ThumbnailProps = {
    renderId: string,
    thumbnailUrl: string,
    onClickInfo: string => void,
    onClickImage: string => void,
};

const ThumbnailImage = ({
    renderId,
    thumbnailUrl,
    onClickImage: handleClickImage,
    onClickInfo: handleClickInfo,
}: ThumbnailProps): Node => {
    const [isShown, setIsShown] = useState(false);
    const [loaded, setLoaded] = useState(false);
    return (
        <div
            key={renderId}
            className={style.sliderContainerWrapperItem}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
        >
            <img
                alt={renderId}
                src={thumbnailUrl}
                onLoad={() => setLoaded(true)}
            />
            {loaded && isShown && (
                <div className={style.sliderContainerWrapperItemButtons}>
                    <Button onClick={() => handleClickInfo(renderId)}>
                        <Icon icon={Information} />
                    </Button>
                    <Button onClick={() => handleClickImage(renderId)}>
                        <Icon icon={Search} />
                    </Button>
                </div>
            )}
        </div>
    );
};

export default RenderSlider;
