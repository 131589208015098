// @flow

import {
    Button,
    Card,
    Datatable,
    DetailsForm,
    FileUpload,
    Heading,
    Spinner,
    Stepper,
    useStepper,
} from "@brutextiles/web-component-library";
import { useFormik } from "formik";
import { navigate } from "gatsby";
import React, {
    type Node,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";
import { Container } from "reactstrap";

import { getUploadStatus } from "../../api";
import { Comment, ErrorHandling } from "../../components";
import {
    useCreate,
    useErrorHandlingWithForm,
    useSuggestions,
    useTableData,
    useUpload,
} from "../../hooks";
import { FormContext } from "../../providers/form-provider";
import sharedStyle from "../../styles/shared.module.scss";
import styles from "./create-noise-mask.module.scss";
import detailSettings from "./detail-settings";
import tableSettings from "./table-settings";
import validationSchema from "./validation-settings";

const CreateNoiseMask = (): Node => {
    const apiBase = "dap-search-service/erpdesign";

    const [
        {
            progress: uploadProgress,
            done: uploadFinished,
            loading: uploadLoading,
            uploadData,
            errorOnUpload,
        },
        uploadFile,
        resetUpload,
    ] = useUpload("/ams-api/noisemask");

    const [fileValidationInProgress, setFileValidationInProgress] = useState();

    const [uploadStatus, setUploadStatus] = useState();

    useEffect(() => {
        if (uploadData?.Location) {
            setDirty(true);
            setFileValidationInProgress(true);
            getUploadStatus(
                "/ams-api/noisemask/upload/status",
                {
                    s3Url: uploadData.Location,
                },
                setFileValidationInProgress,
                setUploadStatus,
            );
        }
    }, [uploadData]);

    useEffect(() => {
        if (uploadStatus?.status === "IN_PROGRESS") {
            setTimeout(
                () =>
                    getUploadStatus(
                        "/ams-api/noisemask/upload/status",
                        {
                            s3Url: uploadData.Location,
                        },
                        setFileValidationInProgress,
                        setUploadStatus,
                    ),
                3000,
            );
        } else if (uploadStatus) {
            setFileValidationInProgress(false);
        }
    }, [uploadStatus]);

    const [
        { tableData, tableDataLoading, page },
        changeSorting,
        setSearchFilters,
        search,
        ,
        ,
        changePageInfo,
    ] = useTableData(
        {
            size: tableSettings.itemsPerPage || 10,
            sortField: tableSettings.sortField,
        },
        apiBase,
    );

    const [
        { suggestions, suggestionsLoading },
        setSuggestionFilters,
        setFilterQuery,
    ] = useSuggestions(apiBase);

    const [{ createLoading }, create] = useCreate({
        overviewUrl: "/noise-masks",
    });

    const [{ steps, activeStep }, setActiveStep, setStepvalidity] = useStepper([
        {
            label: "Upload and link noise mask",
            name: "step1",
            valid: false,
            active: true,
        },
        {
            label: "Noise mask summary",
            name: "step2",
            active: false,
            valid: false,
        },
    ]);

    const [design, setDesign] = useState();
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);

    const formik = useFormik({
        initialValues: {
            height: "",
            width: "",
            comment: "Initial upload",
            noiseAddFactor: "0.2",
        },
        validationSchema,
        onSubmit: () =>
            create({
                url: `/ams-api/noisemask/${design?.companyId || ""}/${
                    design?.designId || ""
                }`,
                data: {
                    comment: formik.values.comment,
                    s3FileUrl: uploadData?.Location,
                    noiseMaskType: "PNG",
                    width: Number(formik.values.width),
                    height: Number(formik.values.height),
                    noiseAddFactor: Number(formik.values.noiseAddFactor),
                },
            }),
    });

    const { setDirty } = useContext(FormContext);
    useEffect(() => setDirty(formik.dirty), [formik.dirty]);

    useErrorHandlingWithForm("create-noise-mask-form", formik.errors);

    useEffect(() => {
        if (activeStep === 0) {
            if (
                design &&
                uploadData?.Location &&
                uploadStatus?.status === "VALID"
            ) {
                setStepvalidity(0, true);
            } else {
                setStepvalidity(0, false);
            }
        }
        if (activeStep === 1 && formik.isValid && uploadData?.Location) {
            setStepvalidity(1, true);
        }
    }, [design, activeStep, formik.isValid, uploadData, uploadStatus]);

    const validateStep = () => {
        if (activeStep === 0) {
            if (!design || !uploadData?.Location) {
                return setStepvalidity(0, false);
            }
            setActiveStep(1);
        } else if (activeStep === 1) {
            if (!uploadData?.Location) {
                return setStepvalidity(1, false);
            }
            setShouldValidateOnChange(true);
            formik.handleSubmit();
        }
    };

    const setFilters = (filters: Array<{ [string]: string }>): void => {
        setSearchFilters(filters);
        setSuggestionFilters(filters);
    };

    const actionButtons = (
        <Fragment>
            <Button
                className="mr-3"
                outline
                onClick={() => navigate("/noise-masks")}
            >
                Cancel
            </Button>
            {steps[0].active ? (
                <Button
                    disabled={!steps[0].valid || fileValidationInProgress}
                    onClick={validateStep}
                    arrow
                >
                    {fileValidationInProgress ? <Spinner size="sm" /> : "Next"}
                </Button>
            ) : (
                <Button
                    disabled={!formik.isValid || !steps[1].valid}
                    onClick={validateStep}
                >
                    {createLoading ? <Spinner size="sm" /> : "Create"}
                </Button>
            )}
        </Fragment>
    );

    return (
        <Container fluid className={sharedStyle.wizard}>
            <div className={sharedStyle.stepper}>
                <Stepper steps={steps} onStepChange={setActiveStep} />
            </div>
            <Heading title="Create Noise Mask" rightContent={actionButtons} />
            <ErrorHandling />
            <Heading title={"Upload Noise Mask"} level={5} />
            <Card>
                <FileUpload
                    onAddFile={uploadFile}
                    infoContent={
                        (uploadStatus && uploadStatus.status === "VALID") ||
                        fileValidationInProgress ||
                        uploadLoading
                            ? ""
                            : "Please select a valid .png file for upload"
                    }
                    validExtensions=".png"
                    isUploading={uploadLoading}
                    progress={uploadProgress}
                    uploadFinished={uploadFinished}
                    onReset={resetUpload}
                    error={errorOnUpload}
                />
                {!!uploadData && !uploadData?.Location && (
                    <small className="text-center text-danger mt-2">
                        Upload Required
                    </small>
                )}
            </Card>
            {steps[0].active ? (
                <Fragment>
                    <Heading title={"Link Design Id"} level={5} />
                    <div className={styles.tableWrapper}>
                        <Datatable
                            settings={tableSettings}
                            data={tableData?.results || []}
                            multiSort={true}
                            onMultiSortChange={changeSorting}
                            onPageInfoChange={changePageInfo}
                            searchResults={suggestions?.result}
                            onSearchChange={setFilterQuery}
                            onRefresh={search}
                            onFilterChange={setFilters}
                            searchIsLoading={suggestionsLoading}
                            dataIsLoading={tableDataLoading}
                            totalResults={tableData?.totalResults}
                            disableRowCheck={row => !!row.linkedToAmsNoiseMask}
                            onRowSelect={setDesign}
                            page={page}
                        />
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <Heading title={"Noise Mask Details"} level={5} />
                    <Card>
                        <DetailsForm
                            settings={detailSettings(false)}
                            data={{
                                ...design,
                                ...formik.values,
                            }}
                            onChange={data =>
                                formik.setFieldValue(
                                    data.key,
                                    data.value,
                                    shouldValidateOnChange,
                                )
                            }
                            errors={formik.errors}
                        />
                    </Card>
                    <Comment
                        comment={formik.values.comment}
                        onChange={value =>
                            formik.setFieldValue(
                                "comment",
                                value,
                                shouldValidateOnChange,
                            )
                        }
                        error={formik.errors.comment}
                    />
                </Fragment>
            )}
        </Container>
    );
};

export default CreateNoiseMask;
