//@flow

import {
    ArrowRightUp,
    ArrowUp,
    Button,
    DropdownList,
    InlineEdit,
    Modal,
    useNotifications,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import classnames from "classnames";
import React, { type Node, useEffect, useState } from "react";

import { useSuggestions, useTableData, useValidation } from "../../hooks";
import { SetType } from "../../hooks/use-material-sets";
import Header from "../ViewSet/subComponents/Header";
import style from "./style.module.scss";
import MaterialSetBody from "./subComponents/MaterialSetBody";
import TitleInput from "./subComponents/TitleInput";
import type { MaterialVersion } from "./types/material-version.d";

export type Settings = {
    columns: {
        key: string,
        label: string,
        editable?: boolean,
        thumbnail?: boolean,
        formatter?: (value: any) => string,
    }[],
    rowId: string,
    itemsPerPage?: number,
};

export type Actions = {
    icon: Node,
    label: string,
    action: string,
    enable?: {
        key: string,
        condition: () => boolean,
    },
}[];

type Props = {
    projectId: string,
    set: {
        [string]: any,
    },
    addedMaterials?: any[],
    loadMaterials?: boolean,
    actions: Actions[],
    queryTitle?: string,
    order: number,
    onDelete: () => void,
    open: boolean,
    enableEdit?: boolean,
    onCancel: () => void,
    onAddItem: ({
        [string]: any,
    }) => void,
    onUpdateTitle: string => void,
    onUpdateQuery?: string => void,
    onDeleteItem: (row: {
        [string]: any,
    }) => void,
    onChangeRotation: (materialVersionId: string, rotation: number) => void,
    onUpdateMaterialVersion: ({
        [string]: any,
    }) => void,
    loading: boolean,
    onSave: () => Promise<boolean>,
    nameField?: string,
    onToggle: () => void,
    deleteConfirmationLabel: string,
    readOnly?: boolean,
    resultsMapper?: (
        {
            [string]: any,
        }[],
    ) => {
        [string]: any,
    }[],
};

const MaterialSet = ({
    projectId,
    set,
    order,
    queryTitle,
    onDelete: handleDelete,
    addedMaterials = [],
    loadMaterials = false,
    open,
    enableEdit,
    onCancel: handleCancel,
    onSave: handleSave,
    onAddItem: handleAddItem,
    actions,
    onUpdateTitle: handleUpdateTitle,
    onUpdateQuery: handleUpdateQuery,
    onDeleteItem: handleDeleteItem,
    onChangeRotation: handleChangeRotation,
    onUpdateMaterialVersion: handleUpdateMaterialVersion,
    loading,
    nameField,
    onToggle: handleToggle,
    deleteConfirmationLabel,
    readOnly,
}: Props): Node => {
    const { addNotification } = useNotifications();
    const [editMode, setEditMode] = useState(!!enableEdit);
    const [showModal, setShowModal] = useState();
    useEffect(() => setEditMode(!!enableEdit), [enableEdit]);

    const [, fetchMaterialLatestVersions] = useAxios(
        { url: `ams-api/material/material-latest-versions`, method: "POST" },
        { manual: true, useCache: false },
    );

    const [, fetchMaterialLatestPublishedVersions] = useAxios(
        {
            url: `ams-api/material/material-latest-published-versions`,
            method: "POST",
        },
        { manual: true, useCache: false },
    );

    const [, fetchAllMaterialLatestVersions] = useAxios(
        {
            url: `ams-api/project/${projectId}/materialSets/${set.materialSetId}/material-latest-versions`,
        },
        { manual: true, useCache: false },
    );

    const [, fetchAllMaterialLatestPublishedVersions] = useAxios(
        {
            url: `ams-api/project/${projectId}/materialSets/${set.materialSetId}/material-latest-published-versions`,
        },
        { manual: true, useCache: false },
    );

    const handleHeaderAction = (action: string) => {
        const handleResponse = response => {
            if (response?.status === 200) {
                const latestVersions: { [string]: MaterialVersion } =
                    response?.data?.results?.reduce((acc, entry) => {
                        Object.keys(entry).forEach(key => {
                            const newVersion = entry[key];
                            if (newVersion?.materialVersionId !== key) {
                                acc[key] = newVersion;
                            }
                        });
                        return acc;
                    }, {});

                if (Object.keys(latestVersions).length > 0) {
                    if (!open) {
                        handleToggle();
                    }
                    setEditMode(true);
                    handleUpdateMaterialVersion(latestVersions);

                    Object.keys(latestVersions).forEach(
                        (oldMaterialVersionId: string) => {
                            const newVersion: MaterialVersion =
                                latestVersions[oldMaterialVersionId];
                            if (newVersion.rotation) {
                                handleChangeRotation(
                                    newVersion.materialVersionId,
                                    newVersion.rotation,
                                );
                            }
                        },
                    );

                    addNotification({
                        type: "success",
                        body:
                            Object.keys(latestVersions).length === 1
                                ? "1 item has a newer version available."
                                : `${
                                      Object.keys(latestVersions).length
                                  } items have newer versions available for update.`,
                        autoHide: true,
                        timeout: 5000,
                    });
                } else {
                    addNotification({
                        type: "success",
                        body: "Already up-to-date: No updates are available for these items.",
                        autoHide: true,
                        timeout: 5000,
                    });
                }
            }
        };

        if (action === "ALL_TO_LATEST") {
            fetchAllMaterialLatestVersions().then(handleResponse);
        } else {
            fetchAllMaterialLatestPublishedVersions().then(handleResponse);
        }
    };

    const handleAction = (action: string, row: MaterialVersion) => {
        if (action === "REMOVE") {
            setEditMode(true);
            handleDeleteItem(row);
        } else {
            const handleResponse = response => {
                if (response?.status === 200) {
                    const latestVersion = response?.data?.results?.[0];
                    if (!latestVersion) {
                        addNotification({
                            type: "success",
                            body: "Already up-to-date: No updates are available for this item.",
                            autoHide: true,
                            timeout: 5000,
                        });
                    } else if (
                        row.version?.props?.version ===
                        latestVersion.materialVersion
                    ) {
                        addNotification({
                            type: "success",
                            body: "Already up-to-date: No updates are available for this item.",
                            autoHide: true,
                            timeout: 5000,
                        });
                    } else {
                        setEditMode(true);

                        handleUpdateMaterialVersion({
                            [row.materialVersionId]: latestVersion,
                        });

                        if (row.rotation) {
                            handleChangeRotation(
                                latestVersion.materialVersionId,
                                row.rotation,
                            );
                        }
                    }
                }
            };

            if (action === "UPDATE_TO_LATEST") {
                fetchMaterialLatestVersions({
                    data: [`${row.companyId}/${row.skuId}`],
                }).then(handleResponse);
            } else if (action === "UPDATE_TO_LATEST_PUBLISHED") {
                fetchMaterialLatestPublishedVersions({
                    data: [`${row.companyId}/${row.skuId}`],
                }).then(handleResponse);
            }
        }
    };

    const [
        { tableData, tableDataLoading, page },
        changeSorting,
        changeFilters,
        search,
        ,
        ,
        changePageInfo,
    ] = useTableData(
        {
            size: 5,
        },
        `dap-search-service/project-material/${set.materialSetId}`,
        60,
        !open,
    );

    const [
        { suggestions, suggestionsLoading },
        setSuggestionFilters,
        setFilterQuery,
    ] = useSuggestions(
        `dap-search-service/project-material/${set.materialSetId}`,
    );

    const setFilters = (filters: Array<{ [string]: string }>): void => {
        changeFilters(filters);
        setSuggestionFilters(filters);
    };

    const [{ formik }, submit, unregisterFields] = useValidation({
        title: set.materialSetName,
        data: [],
        nameField,
        onSave: async () => {
            const success = await handleSave();
            if (success) {
                setEditMode(false);
                changePageInfo(draft => ({ ...draft, page: 0 }));
            }
        },
    });

    return (
        <div
            className={classnames(
                "px-0",
                editMode ? style.expanded : style.collapsed,
            )}
        >
            <InlineEdit
                isSubmitting={loading}
                active={editMode}
                onCancel={() => {
                    unregisterFields();
                    setEditMode(false);
                    handleCancel();
                }}
                onSave={submit}
                isOpen={open}
                saveAllowed={formik.isValid && !loadMaterials}
            >
                <Header
                    readOnly={readOnly}
                    order={order}
                    isOpen={open}
                    onToggle={handleToggle}
                    editMode={editMode}
                    onEnableEditMode={() => setEditMode(true)}
                    title={set.materialSetName}
                    onDelete={() => setShowModal(true)}
                    validationError={formik.errors["title"]}
                    onUpdateTitle={handleUpdateTitle}
                    loading={loading}
                    leftAction={
                        set.type === SetType.SELECTION && (
                            <DropdownList
                                direction={"down"}
                                listItems={[
                                    {
                                        label: "Update all to latest",
                                        value: "ALL_TO_LATEST",
                                        icon: ArrowUp,
                                    },
                                    {
                                        label: "Update all to latest published",
                                        value: "ALL_TO_LATEST_PUBLISHED",
                                        icon: ArrowRightUp,
                                    },
                                ]}
                                onSelect={value =>
                                    !loading &&
                                    handleHeaderAction &&
                                    handleHeaderAction(value)
                                }
                            >
                                <Button color={"white"}>
                                    <ArrowRightUp />
                                </Button>
                            </DropdownList>
                        )
                    }
                >
                    <TitleInput
                        set={set}
                        editMode={editMode}
                        onUpdateTitle={handleUpdateTitle}
                        error={formik.errors["title"]}
                    />
                </Header>
                <MaterialSetBody
                    set={set}
                    open={open}
                    order={order}
                    editMode={editMode}
                    actions={actions}
                    onAddItem={handleAddItem}
                    tableDataLoading={tableDataLoading}
                    suggestionsLoading={suggestionsLoading}
                    changeSorting={changeSorting}
                    changePageInfo={changePageInfo}
                    suggestions={suggestions}
                    setFilterQuery={setFilterQuery}
                    search={search}
                    setFilters={setFilters}
                    tableData={tableData}
                    page={page}
                    onAction={handleAction}
                    onChangeRotation={handleChangeRotation}
                    addedMaterials={addedMaterials}
                    onUpdateQuery={handleUpdateQuery}
                    queryTitle={queryTitle}
                />
            </InlineEdit>
            <Modal
                open={showModal}
                onCancel={() => setShowModal(false)}
                title={"confirmation"}
                actions={[
                    {
                        type: "primary",
                        label: "Delete",
                        action: () => {
                            setShowModal(false);
                            handleDelete();
                        },
                    },
                    {
                        type: "secondary",
                        label: "Cancel",
                        action: () => setShowModal(false),
                    },
                ]}
                confirmation
            >
                {deleteConfirmationLabel}
            </Modal>
        </div>
    );
};

export default MaterialSet;
