import settings from "../NoiseMaskOverview/table-settings";

export default {
    ...settings,
    columns: settings.columns.filter(
        setting =>
            ![
                "noiseMaskType",
                "modificationTime",
                "version",
                "published",
                "width",
                "height",
                "noiseAddFactor",
            ].includes(setting.key),
    ),
    rowId: "designId",
    itemsPerPage: 10,
    pageSizeOptions: [
        { label: "10", value: "10" },
        { label: "25", value: "25" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
    ],
};
