import settings from "../MaterialOverview/table-settings";

export default {
    ...settings,
    definitions: [
        {
            id: "material-overview",
            label: "Material Overview",
            isDefault: true,
            columns: [
                {
                    key: "companyId",
                    label: "Company ID",
                    width: "6%",
                },
                {
                    key: "skuId",
                    label: "SKU ID",
                    width: "8%",
                },
                {
                    key: "designId",
                    label: "Design ID",
                    width: "8%",
                },
                {
                    key: "brand",
                    label: "Brand",
                    width: "15%",
                },
                {
                    key: "collection",
                    label: "Collection",
                    width: "20%",
                },
                {
                    key: "design",
                    label: "Design",
                    width: "20%",
                },
                {
                    key: "colour",
                    label: "Colour",
                    width: "10%",
                },
                {
                    key: "repeatSize",
                    label: "Repeat size",
                    width: "10%",
                },
            ],
        },
    ],
    rowId: "skuId",
    itemsPerPage: 10,
    pageSizeOptions: [
        { label: "10", value: "10" },
        { label: "25", value: "25" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
    ],
};
