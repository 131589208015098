export default {
    columns: [
        {
            key: "componentId",
            label: "Id",
        },
        {
            key: "name",
            label: "Name",
        },
        {
            key: "description",
            label: "Description",
        },
    ],
};
