//@flow

import { useInterval } from "@restart/hooks";
import useAxios from "axios-hooks";
import { useState } from "react";

export type PageInfo = {
    page: number,
    size: number,
};

const useTableData = (
    settings: {
        page?: number,
        size?: number,
        sortField?: string,
        sortOrder?: string,
        customFilter?: { [string]: string },
        params?: { [string]: string },
    },
    apiBase: string,
    refreshRate?: number,
    disabled?: boolean,
    searchSuffix?: string,
): * => {
    const [searchFilters, setSearchFilters] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    const [silentLoading, setSilentLoading] = useState(false);

    const [customFilter, setCustomFilter] = useState<{ [string]: string } | {}>(
        settings.customFilter || {},
    );

    const [sortBy, setSortBy] = useState(
        settings.sortField &&
            `${settings.sortField} ${settings.sortOrder || "desc"}`,
    );

    const [pageInfo, setPageInfo] = useState<PageInfo>({
        page: settings.page || 0,
        size: settings.size || 50,
    });

    const [{ loading: tableDataLoading, data: tableData }, search] = useAxios(
        {
            url: `/${apiBase}/${searchSuffix || "search"}`,
            method: "POST",
            data: { versionIds: selectedItems },
            // $FlowFixMe
            params: {
                ...pageInfo,
                sortBy,
                ...searchFilters,
                ...customFilter,
                ...settings?.params,
            },
        },
        { useCache: false, manual: disabled },
    );

    if (refreshRate) {
        useInterval(() => {
            if (
                !tableDataLoading &&
                document &&
                !document.hidden &&
                !disabled
            ) {
                setSilentLoading(true);
                search().then(
                    response => {
                        setSilentLoading(false);
                        return response;
                    },
                    () => setSilentLoading(false),
                );
            }
        }, refreshRate * 1000);
    }

    const changePageInfo = (pageInfo): void => {
        setPageInfo(pageInfo);
        search();
    };

    const changeSorting = (fields: { [field: string]: string }): void => {
        setSortBy(sortByBuilder(fields));
    };

    const changeFilters = (filters: Object): void => {
        setSearchFilters(filters);
    };

    const searchSelected = (ids: string[] | void) => {
        setPageInfo(draft => ({ ...draft, page: 0 }));
        setSelectedItems(ids?.length ? ids : []);
    };

    return [
        {
            tableData,
            tableDataLoading: silentLoading ? false : tableDataLoading,
            page: pageInfo.page,
        },
        changeSorting,
        changeFilters,
        search,
        setCustomFilter,
        searchSelected,
        changePageInfo,
    ];
};

const sortByBuilder = (fields: { [field: string]: string }) => {
    let sortBy = "";
    const keys = Object.keys(fields);

    keys.forEach((key, idx) => {
        sortBy += `${key} ${fields[key]}`;

        if (idx < keys.length - 1) {
            sortBy += ", ";
        }
    });

    return sortBy;
};

export default useTableData;
