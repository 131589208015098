export const defaultRowId = "versionId";

export const defaultMaterialTableSettings = [
    { key: "companyId", label: "Company", width: "7%" },
    { key: "skuId", label: "SKU ID", width: "7%" },
    { key: "launch", label: "Launch", width: "7%" },
    { key: "brand", label: "Brand", width: "12%" },
    { key: "collection", label: "Collection", width: "12%" },
    { key: "design", label: "Design", width: "12%" },
    { key: "colour", label: "Colour", width: "12%" },
    { key: "endUse", label: "End use", width: "10%" },
];

export default {
    columns: [
        ...defaultMaterialTableSettings,
        { key: "repeatSize", label: "Repeat size" },
        { key: "modificationTime", label: "Last modified" },
        { key: "version", label: "Version" },
        { key: "state", label: "Status" },
    ],
    itemsPerPage: 10,
    pageSizeOptions: [
        { label: "10", value: "10" },
        { label: "25", value: "25" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
    ],
    rowId: defaultRowId,
    searchLabel: "Search SKU ID, Company etc.",
};
