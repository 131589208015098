export default {
    columns: [
        {
            key: "version",
            label: "Version",
            width: "10%",
        },
        {
            key: "lastModifiedAt",
            label: "Last modified",
            width: "20%",
        },
        {
            key: "lastModifiedBy",
            label: "Modified By",
            width: "60%",
        },
    ],
    rowId: "version",
};
