export default {
    columns: [
        {
            key: "thumbnailUrl",
            label: "Thumbnail",
            thumbnail: true,
            sortable: false,
            width: "10%",
        },
        {
            key: "viewName",
            label: "View name",
            editable: true,
            width: "15%",
        },
        {
            key: "sceneName",
            label: "Scene name",
            width: "15%",
        },
        {
            key: "frameDescription",
            label: "Description",
            width: "15%",
        },
        {
            key: "sceneVersion",
            label: "Scene vr",
            width: "5%",
        },
    ],
    rowId: "frameVersionId",
};
