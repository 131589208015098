export default {
    columns: [
        {
            key: "thumbnailUrl",
            label: "Thumbnail",
            sortable: false,
        },
        {
            key: "cameraName",
            label: "Cam name",
        },
        {
            key: "description",
            label: "Description",
        },
        {
            key: "resolutionRatio",
            label: "Ratio",
        },
        {
            key: "sceneName",
            label: "Scene name",
        },
        {
            key: "sceneVersion",
            label: "Scene vr.",
        },
        {
            key: "assetType",
            label: "Asset",
        },
        {
            key: "roomType",
            label: "Room",
        },
        {
            key: "floorType",
            label: "Floor",
        },
        {
            key: "wallColour",
            label: "Wall colour",
        },
        {
            key: "modificationTime",
            label: "Modification Time",
        },
        {
            key: "lightSetting",
            label: "Lightning",
        },
        {
            key: "sceneType",
            label: "Type",
        },
        {
            key: "published",
            label: "Status",
        },
    ],
    itemsPerPage: 10,
    pageSizeOptions: [
        { label: "10", value: "10" },
        { label: "25", value: "25" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
    ],
    rowId: "frameVersionId",
    searchLabel: "Search SKU ID, Company etc.",
};
