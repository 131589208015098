export default {
    columns: [
        {
            key: "sequence",
            label: "Seq",
        },
        {
            key: "thumbnailUrl",
            label: "Thumbnail",
        },
        {
            key: "camera",
            label: "Camera name",
        },
        {
            key: "label",
            label: "Description",
        },
        {
            key: "ratio",
            label: "Scene ratio",
        },
        {
            key: "compositingTemplateFilePath",
            label: "Compositing template path",
        },
    ],
};
