export default {
    columns: [
        {
            key: "action",
            label: "Action",
            width: "10%",
        },
        {
            key: "modifiedBy",
            label: "Modified By",
            width: "20%",
        },
        {
            key: "modifiedAt",
            label: "Date",
            width: "20%",
        },
        {
            key: "description",
            label: "Description",
            width: "30%",
        },
        {
            key: "picture",
            label: "Picture",
            width: "20%",
        },
    ],
};
